// src/reducers/recReducer.js
import { initialDFState, UPDATE_DF_PAYLOAD } from '../states/dfState';

const dfReducer = (state = initialDFState, action) => {
  switch (action.type) {
    case UPDATE_DF_PAYLOAD:
      return {
        ...state,
        payload: action.payload,
      };
    default:
      return state;
  }
};

export default dfReducer;
