const auth_config = require("../../../auth/auth_config.json");

class AptGptUtility {
    static BASE_URL = "http://localhost:9000";

    constructor(getAccessTokenSilently, isAuthenticated) {
        this.getAccessTokenSilently = getAccessTokenSilently;
        this.isAuthenticated = isAuthenticated;
    }

    async chat_init(ask, recs) {
        const res = await this.post("chat/init", {
            ask, recs
        }, true);
        return res.data;
    }

    async chat_next(msgs) {
        const res = await this.post("chat/next", {
            msgs
        }, true);
        return res.data;
    }

    async chat_suggestion(rec, ask, payload) {
        const res = await this.post("chat/suggestion", {
            rec, ask, payload
        }, true);
        return res.data;
    }

    async get(endpoint, exitOnUnauthorized = false) {
        const accessToken = await this.fetchAccessToken(); // Fetch access token
    
        if (exitOnUnauthorized && !accessToken) {
          console.warn('User is not authenticated. Exiting request early.');
          return null;
        }
    
        const headers = {};
        if (accessToken) {
          headers.Authorization = `Bearer ${accessToken}`; // Include authorization token
        }
    
        const url = `${AptGptUtility.BASE_URL}/${endpoint}`; // Construct the full URL
        try {
          const response = await fetch(url, {
            headers,
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        } catch (error) {
          console.error('There was a problem with the HTTP GET request:', error);
          throw error;
        }
      }
    
      async post(endpoint, data, exitOnUnauthorized = false) {
        const accessToken = await this.fetchAccessToken(); // Fetch access token
    
        if (exitOnUnauthorized && !accessToken) {
          console.warn('User is not authenticated. Exiting request early.');
          return null;
        }
    
        const headers = {
          'Content-Type': 'application/json',
        };
    
        if (accessToken) {
          headers.Authorization = `Bearer ${accessToken}`; // Include authorization token
        }
    
        const url = `${AptGptUtility.BASE_URL}/${endpoint}`; // Construct the full URL
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
          });
          if (!response.ok) {
            console.log(response);
            throw new Error('Network response was not ok');
          }
          return response.json();
        } catch (error) {
          console.error('There was a problem with the HTTP POST request:', error);
          throw error;
        }
      }

      async fetchAccessToken() {
    
        if (!this.isAuthenticated) {
          console.warn('User is not authenticated. Cannot fetch access token.');
          return null;
        }
    
        try {
          const accessToken = await this.getAccessTokenSilently({
            audience: `https://${auth_config.domain}/api/v2/`, // Replace with your API identifier
            scope: 'read:current_user',
          });
          return accessToken;
        } catch (error) {
          console.error('Error fetching access token:', error);
          throw error;
        }
      }
  }
  
  export default AptGptUtility;
  