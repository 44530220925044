import React, {useState} from 'react';
import ReactMarkdown from 'react-markdown';
import { Card, Icon, List, Grid, Segment, Rating, Accordion } from 'semantic-ui-react';


import './PropertyDetail.css';
import CoolPlace from './CoolPlace';

const PropertyDetail = ({ data }) => {
  const { img, address, description, 
    rent, baths, distance, beds, suggestion, 
    walkability, transit, places_of_interest, averages,
    latitude, longitude } = data;
  console.log(data, "K");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === img.length - 1 ? 0 : prevSlide + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? img.length - 1 : prevSlide - 1));
  };

  return (
    <div className="property-detail-container">
      <div className="slideshow-container">
        {img.map((image, index) => (
          <div key={index} className={index === currentSlide ? "slide active" : "slide"}>
          <img src={image} alt={`Slide ${index + 1}`} />
        </div>
        ))}
        <button className="prev" onClick={prevSlide}>&#10094;</button>
        <button className="next" onClick={nextSlide}>&#10095;</button>
      </div>

      <Segment padded='very' style={{ maxWidth: '1000px', margin: 'auto' }}>
      <Card fluid>
        <Card.Content>
          <Card.Header textAlign='center'>{address}</Card.Header>
          <Card.Meta textAlign='center'>
            {distance.toFixed(2)} miles from your location
          </Card.Meta>
        </Card.Content>
        <Card.Content>
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column>
                <Icon name='bed' /> Bedrooms: {beds}
              </Grid.Column>
              <Grid.Column>
                <Icon name='bath' /> Bathrooms: {baths}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
        <Card.Content>
          <Grid columns={1}>
            <Grid.Row>
              <Grid.Column>
                <Icon name='money bill alternate outline' /> Monthly Rent: ${rent}
                (Avg: ${averages.averageRent.toFixed(2)})
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
        <Card.Content>
          <Card.Description>
            Property Management Description: <br/>
            {description}
          </Card.Description>
        </Card.Content>
        <Card.Content>
          <Icon name='lightbulb' /> AI Analysis: 
          <ReactMarkdown style={{"text-align": "left"}}>{suggestion}</ReactMarkdown>
        </Card.Content>
        <Card.Content>
          <Icon name='street view' /> Walkability Score:
          <Rating icon='star' defaultRating={walkability.walkscore / 20} maxRating={5} disabled />
          (Avg: <Rating icon='star' defaultRating={averages.averageWalkability / 20} maxRating={5} disabled />)
          <br/>
          <Icon name='bus' /> Transit Score:
          <Rating icon='star' defaultRating={transit.transit_score / 20} maxRating={5} disabled />
          (Avg: <Rating icon='star' defaultRating={averages.averageTransit / 20} maxRating={5} disabled />)
        </Card.Content>
        <Card.Content>
          <Card.Header>Places of Interest</Card.Header>
          <Accordion styled fluid>
            {Object.entries(places_of_interest).map(([category, places], index) => (
              <CoolPlace 
                index={index}
                category={category}
                places={places}
                handleAccordionClick={handleAccordionClick}
                activeIndex={activeIndex}
                keypoint={[latitude, longitude]}
                />
            ))}
          </Accordion>
        </Card.Content>
      </Card>
    </Segment>
    </div>
  );
};

export default PropertyDetail;
