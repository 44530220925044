export const initialRecState = {
    recs: [],
    hash: "",
    email: "",
    formData: {}
  };
  
export const UPDATE_RECS = 'UPDATE_RECS';
export const SET_REC_HASH = 'SET_REC_HASH';
export const SET_REC_EMAIL = 'SET_REC_EMAIL';
export const SET_FORM_DATA = 'SET_FORM_DATA';

  