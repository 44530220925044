import { useState } from 'react';
import { Container } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import { Range } from 'react-range';


import './ApartmentFormModal.css';
import { CensusGeocoder } from '../utils/Census/CensusGeocoder';
import { updateDFPayload } from '../../store/actions/dfActions';
import { updateFormDataPayload } from '../../store/actions/formDataActions';
import { setRecHash } from "../../store/actions/recActions";

const CHOICES = {
  5: "< 5 miles",
  10: "< 10 miles",
  20: "< 20 miles",
  40: "< 40 miles",
  100: "< 100 miles"
};

const AUSTIN_DATA = require("../../data/austin_cleaned.json");

const NEIGHBORS = [
  {name: "Downtown", street: "600 Congress Ave", city: "Austin", zip_code: "78701"},
  {name: "Rainey Street", street: "70 Rainey St", city: "Austin", zip_code: "78701"},
  {name: "Red River Cultural District", street: "801 Red River St", city: "Austin", zip_code: "78701"},
  {name: "Sixth Street", street: "412 E 6th St", city: "Austin", zip_code: "78701"},
  {name: "Warehouse District", street: "400 Lavaca St", city: "Austin", zip_code: "78701"},
  {name: "Seaholm District", street: "222 West Ave", city: "Austin", zip_code: "78701"},
  {name: "The Aboretum", street: "10000 Research Blvd", city: "Austin", zip_code: "78759"},
  {name: "The Domain", street: "11301 Domain Dr", city: "Austin", zip_code: "78758"},
  {name: "North Austin Civic Association", street: "9708 Oak Hollow Dr", city: "Austin", zip_code: "78758"},
  {name: "South Congress Avenue", street: "1522 S Congress Ave", city: "Austin", zip_code: "78704"},
  {name: "Bouldin Creek", street: "1401 Bouldin Ave", city: "Austin", zip_code: "78704"},
  {name: "Zilker", street: "1406 Garner Ave", city: "Austin", zip_code: "78704"},
  {name: "Barton Hills", street: "2598 Barhill Dr", city: "Austin", zip_code: "78704"},
  {name: "South Lamar", street: "3418 Larchmont Dr", city: "Austin", zip_code: "78704"},
  {name: "Galindo", street: "2809 S 5th St", city: "Austin", zip_code: "78704"},
  {name: "Travis Heights", street: "1513 Alameda Dr", city: "Austin", zip_code: "78704"},
  {name: "Riverside", street: "1902 Woodland Ave", city: "Austin", zip_code: "78704"},
  {name: "East Caesar Chavez", street: "99 Navasota St", city: "Austin", zip_code: "78702"},
  {name: "Central East Austin", street: "1400 Cotton St", city: "Austin", zip_code: "78702"},
  {name: "Mueller", street: "4413 Berkman Dr", city: "Austin", zip_code: "78723"},
  {name: "Cherrywood", street: "1600 E 34th St", city: "Austin", zip_code: "78722"},
  {name: "Windsor Park", street: "1711 Wheless Ln", city: "Austin", zip_code: "78723"},
  {name: "Hyde Park", street: "4400 Avenue G", city: "Austin", zip_code: "78751"},
  {name: "Allandale", street: "2705 Twin Oaks Dr", city: "Austin", zip_code: "78757"},
  {name: "West University", street: "600 W 26th St", city: "Austin", zip_code: "78705"},
  {name: "North University", street: "106 W 32nd St", city: "Austin", zip_code: "78705"},
  {name: "University of Texas", street: "110 Inner Campus Drive", city: "Austin", zip_code: "78712"},
  {name: "North Loop", street: "501 E 53rd St", city: "Austin", zip_code: "78751"},
]
// clarksville, old west austin, tarry town, east casear chavez, holly, cherrywood, mueller, hyde park, north loop, rosedale
// allandale, crestview, brentwood, wooten, highland, tech ridge, georgian acres, old enfield, northwest hills, north shole creek
// st johns, barton springs, west campus, north university, hancock, 

// https://www.reddit.com/r/ChatGPT/comments/13w9o48/anyone_have_a_good_ai_recommendation_for_finding/

const ApartmentFormModal = ({ showLoading }) => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, control, setValue } = useForm({
    defaultValues: {
      rent_range: [1000, 2000]
    }
  });
  const dispatch = useDispatch();
  const [useAddress, setUseAddress] = useState(true);

  let str = "";
  NEIGHBORS.forEach(n=>{
    str += n.name+", "
  })
  console.log(str);

  const getCoordinates = async (targetLocation) => {
    const geocoder = new CensusGeocoder();
    const { street, city, zip } = targetLocation;
    const censusResults = await geocoder.geocode(street, city, zip);
    return censusResults[0];
  };

  const dataCompilation = async (newFormData) => {
    const [minRent, maxRent] = newFormData.rent_range;
    const maxDistance = parseFloat(newFormData.distance);
    let df;

    switch (newFormData.city) {
      case 'Austin':
        df = AUSTIN_DATA;
        break;
    }

    df = df.filter(row => row.description !== 'Failed to parse description.');
    df = df.filter(row => row.price <= maxRent && row.price >= minRent);
    df = df.filter(row=> row.bathrooms == undefined);
    df = df.filter(row=> row.bedrooms == undefined);

    df.forEach(row => {
      row.distance = newFormData.coordinates.distanceFrom(row.latLong.latitude, row.latLong.longitude);
    });

    df = df.filter(row => row.distance <= maxDistance);
    df.sort((a, b) => a.distance - b.distance);

    return df;
  };

  const handleFormSubmit = async (data) => {
    console.log(data, "data", data.neighborhood, NEIGHBORS[data.neighborhood]);
    let params = {
      street: data.street,
      city: data.city,
      zip: data.zip_code
    }
    if (!useAddress) {
      let neighborhoodData = NEIGHBORS[data.neighborhood];
      params = {
        street: neighborhoodData.street,
        city: neighborhoodData.city,
        zip: neighborhoodData.zip_code
      }
    }
    let coordinates = await getCoordinates(params);
    console.log(coordinates, params, "COO");
    let newFormData = {
      city: data.metro,
      coordinates: coordinates,
      rent_range: data.rent_range,
      distance: data.distance,
      ask: data.ask
    };

    const df = await showLoading(dataCompilation, newFormData);
    dispatch(updateDFPayload(df));
    dispatch(setRecHash(null));
    // Set the whole most recent rec to be null

    dispatch(updateFormDataPayload(newFormData));
    navigate('/view');
  };

  return (
    <Container>
      <h2>What do you want in an apartment:</h2>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div>
          <label>I'm looking in ...</label>
          <select {...register("metro")}>
            <option value="Austin">Austin</option>
          </select>
        </div>
        <div>
          <label>I want an apartment</label>
          <select {...register("distance")}>
            {Object.entries(CHOICES).map(([key, value]) => (
              <option key={key} value={key}>{value}</option>
            ))}
          </select>
          <span>from...</span>
        </div>
        <div>
          <label>
            <input
              type="radio"
              name="locationChoice"
              checked={useAddress}
              onChange={() => setUseAddress(true)}
            />
            Enter an address
          </label>
          <label>
            <input
              type="radio"
              name="locationChoice"
              checked={!useAddress}
              onChange={() => setUseAddress(false)}
            />
            Select a neighborhood
          </label>
        </div>
        {useAddress ? (
          <div>
            <input type="text" placeholder="Street Name" {...register("street")} />
            <input type="text" placeholder="City" {...register("city")} />
            <input type="text" placeholder="Zip Code" {...register("zip_code")} />
          </div>
        ) : (
          <div>
            <select {...register("neighborhood")}>
              {NEIGHBORS.map((neighborhood, index) => (
                <option key={index} value={index}>{neighborhood.name}</option>
              ))}
            </select>
            <FaInfoCircle 
              data-tooltip-id="neighborhood-tooltip"
              style={{ marginLeft: '10px', cursor: 'pointer' }} 
            />
            <Tooltip id="neighborhood-tooltip">
              Don't see a neighborhood you want? Send us an email at mgunton7 [at] gmail.com
            </Tooltip>
          </div>
        )}
<div>
          <label>Monthly rent range:</label>
          <Controller
            name="rent_range"
            control={control}
            render={({ field }) => (
              <Range
                step={50}
                min={500}
                max={5000}
                values={field.value}
                onChange={(values) => setValue("rent_range", values)}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    className="range-track"
                    style={{
                      background: `linear-gradient(to right, lightgrey ${100 * (field.value[0] - 500) / (5000 - 500)}%, blue ${100 * (field.value[0] - 500) / (5000 - 500)}%, blue ${100 * (field.value[1] - 500) / (5000 - 500)}%, lightgrey ${100 * (field.value[1] - 500) / (5000 - 500)}%)`
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    {...props}
                    className="range-thumb"
                  />
                )}
              />
            )}
          />
          <div>
            <span>{`Min: $${watch("rent_range")[0]}`}</span>
            <span style={{ marginLeft: '20px' }}>{`Max: $${watch("rent_range")[1]}`}</span>
          </div>
        </div>
        <div>
          <label>What do you want in your apartment:</label>
          <input type="text" {...register("ask")} />
        </div>
        <button type="submit">Submit</button>
      </form>
    </Container>
  );
};

export default ApartmentFormModal;
