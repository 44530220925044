import ReactGA from 'react-ga';

const initializeAnalytics = () => {
  ReactGA.initialize('G-DYF57M0YYV'); 
};

const logPageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export { initializeAnalytics, logPageView };