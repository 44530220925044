import { useState } from 'react';
import { Button, Input } from 'semantic-ui-react';
import styled from 'styled-components';
import robotIcon from './robo.jpeg'; 
import userIcon from './user.jpeg'; 
import ReactMarkdown from 'react-markdown';

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #f0f2f5;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
`;

const MessagesContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    margin-bottom: 10px;
`;

const Message = styled.div`
    margin: 5px 0;
    padding: 10px;
    border-radius: 8px;
    background-color: ${(props) => (props.isUser ? '#d1e7dd' : '#e2e3e5')};
    align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
    max-width: 80%;
    color: #333;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    display: flex;
`;

const MessageIcon = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 10px;
`;

const MessageText = styled.div`
    flex: 1;
    text-align: left;
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #ffffff;
    border-top: 1px solid #ccc;
    box-shadow: 0 -1px 3px rgba(0,0,0,0.1);
    position: sticky;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    gap: 10px;
`;

const CustomInput = styled(Input)`
    flex: 1;
    & > input {
        border-radius: 30px;
        border: 1px solid transparent; /* Set border color to transparent */
        padding: 10px;
        box-shadow: none;
        transition: border-color 0.3s ease; /* Add transition effect */
        border-color: #ccc;
    }
    /* Add focus effect to the input border */
    &:focus-within > input {
        border-color: #007bff;
    }
`;

const StyledButton = styled(Button)`
    border-radius: 30px !important;
    padding: 10px 20px !important;
    font-weight: bold !important;
    box-shadow: none !important;
    border: none !important; /* Remove border */
    &:hover {
        opacity: 0.8;
    }
`;

const SendButton = styled(StyledButton)`
    background-color: #007bff !important;
    color: white !important;
`;

const ClearButton = styled(StyledButton)`
    background-color: #dc3545 !important;
    color: white !important;
`;

const SuggestionsContainer = styled.div`
    display: ${(props) => (props.show ? 'flex' : 'none')}; /* Display only if show prop is true */
    flex-direction: row; /* Align items horizontally */
    flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
    justify-content: space-evenly; /* Evenly spread out items */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    z-index: 1;
    margin-top: -160px; /* Adjust as needed */
`;

const SuggestionItem = styled.div`
    padding: 10px;
    cursor: pointer;
    &:hover {
        background-color: #f0f0f0;
        color: #007bff;
    }
    margin: 5px; /* Add margin between suggestion items */
`;


export default function ChatBox({ messages, onUserMessage, onClearHistory }) {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([
        'How are you?',
        'Tell me a joke.',
        'What is the weather like?',
    ]);
    const [showSuggestions, setShowSuggestions] = useState(true);

    const handleSend = () => {
        if (inputValue.trim() !== '') {
            onUserMessage(inputValue);
            setInputValue('');
            setSuggestions([]);
            setShowSuggestions(false);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (value.trim() === '') {
            // Example suggestions, replace with your own logic
            const exampleSuggestions = [
                'How are you?',
                'Tell me a joke.',
                'What is the weather like?',
            ].filter((s) => s.toLowerCase().includes(value.toLowerCase()));

            setSuggestions(exampleSuggestions);
            setShowSuggestions(exampleSuggestions.length > 0);
        } else {
            setShowSuggestions(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setInputValue(suggestion);
        setSuggestions([]);
        setShowSuggestions(false);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSend();
        }
    };

    return (
        <ChatContainer>
            <MessagesContainer>
                {messages.map((msg, index) => (
                    <Message key={index} isUser={msg.role === 'user'}>
                        <MessageIcon
                            src={msg.role === 'user' ? userIcon : robotIcon}
                            alt={msg.role === 'user' ? 'User' : 'Assistant'}
                        />
                        <MessageText>
                            <b>{msg.role === 'user' ? 'User' : 'Assistant'}:</b>
                            <ReactMarkdown>{msg.content}</ReactMarkdown>
                        </MessageText>
                    </Message>
                ))}
            </MessagesContainer>
            <SuggestionsContainer show={showSuggestions}>
                {suggestions.map((suggestion, index) => (
                    <SuggestionItem key={index} onClick={() => handleSuggestionClick(suggestion)}>
                        {suggestion}
                    </SuggestionItem>
                ))}
            </SuggestionsContainer>
            <InputContainer>
                <CustomInput
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    placeholder='Ask me more...'
                />
                <SendButton onClick={handleSend}>
                    Send
                </SendButton>
                <ClearButton onClick={onClearHistory}>
                    Clear History
                </ClearButton>
            </InputContainer>
        </ChatContainer>
    );
}