import React from 'react';
import { Menu, Button, Dropdown } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';

import './Header.css';
import LoginDropdown from "./LoginDropdown";

const Header = ({  }) => {
  const location = useLocation();

  return (
    <Menu className="header-menu">
      <Menu.Menu position='left'>
        <Dropdown item icon='bars' simple>
          <LoginDropdown />
        </Dropdown>
      </Menu.Menu>
      <Menu.Item className="menu-item">
        <Button 
            as={Link} 
            to='/view'
            primary={location.pathname === '/view'}
        >
            Recommendations
        </Button>
      </Menu.Item>
      <Menu.Item className="menu-item">
        <Button 
            as={Link} 
            to='/'
            primary={location.pathname === '/'}
        >
            Form
        </Button>
      </Menu.Item>
      <Menu.Item className="menu-item">
        <Button 
            as={Link} 
            to='/chat'
            primary={location.pathname === '/chat'}
        >
            Chat
        </Button>
      </Menu.Item>
    </Menu>
  );
};

export default Header;
