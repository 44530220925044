import React, {useState} from 'react';
import { Navigate, Route, Routes, useLocation} from 'react-router-dom';
import { Loader, Dimmer } from 'semantic-ui-react';

import Chat from './components/Chat/Chat';
import View from "./components/View";
import Header from './components/Header/Header';
import ApartmentFormModal from './components/Form/ApartmentFormModal';

import './App.css';
import 'semantic-ui-css/semantic.min.css';
import LandingPage from './components/LandingPage/LandingPage';


function App() {

  const [loading, setLoading] = useState(false);
  const [msgs, setMsgs] = useState([]);
  const location = useLocation();

  const showLoading = async (fn, ...args) => {
    if (typeof fn === 'function') {
      setLoading(true);
      const result = await fn(...args)
      setLoading(false);
      return result;
    } else {
      throw new Error('The provided parameter is not a function');
    }
  }

  return (
    <div className="App">
      {/* {<Header className="header" />}
      <Dimmer active={loading} page>
        <Loader active={loading} inline='centered'>Loading</Loader>
      </Dimmer> */}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/" element={<ApartmentFormModal showLoading={showLoading} />} />
        <Route path="/chat" element={<Chat showLoading={showLoading} />} />
        <Route path="/view" element={<View showLoading={showLoading} msgs={msgs} />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
