import { combineReducers } from 'redux';
import recReducer from './reducers/recReducer';
import shareReducer from './reducers/shareReducer';
import dfReducer from './reducers/dfReducer';
import formDataReducer from "./reducers/formDataReducer";
import pastReducer from './reducers/pastReducer';

const rootReducer = combineReducers({
  rec: recReducer,
  share: shareReducer,
  df: dfReducer,
  formData: formDataReducer,
  past: pastReducer
  // other reducers can be added here
});

export default rootReducer;
